import React from "react";
import image1 from "../assets/image-1.png";

const Hero = () => {
  return (
    <section className="w-screen h-5/6 bg-background px-24 py-14">
      <div className="w-full h-fit shadow-hero-box-shadow border-2 rounded-xl border-secondaryTextColor sm:h-full">
        <h1 className="text-xl text-center mx-auto mt-20 font-bold md:text-6xl animate-slideInLeft">
          Welcome to the{" "}
          <span className="block text-secondaryTextColor text-xl sm:text-8xl">
            future.
          </span>
        </h1>
        <img
          src={image1}
          alt="App Home Scree"
          className="w-[60%] mx-auto sm:-mt-36 animate-slideInBottom"
        />
      </div>
      <div className="sm:hidden flex flex-col items-center justify-center mt-20 animate-bounce text-secondaryTextColor/70">
        <i class="fa-solid fa-angles-down"></i>
        <a href="#features" className="capitalize">
          See more
        </a>
      </div>
    </section>
  );
};

export default Hero;
