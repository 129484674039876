import { BrowserRouter } from "react-router-dom";
import logo from "./assets/logo.png";
import { Link } from "react-router-dom";
import Hero from "./screens/Hero";
import Features from "./screens/Features";
import About from "./screens/About";
import Contact from "./screens/Contact";
import { useState } from "react";

function App() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <BrowserRouter>
      <div className="text-textColor font-poppins w-screen h-screen absolute">
        <nav className="bg-background px-24 flex justify-between w-full h-1/6 sticky top-0 z-40">
          <div className="flex flex-row  items-center">
            <img src={logo} alt="company logo" className="w-14 h-auto" />
            <h2 className="text-2xl font-bold">ReBanker</h2>
          </div>
          <div className="md:flex w-1/2 items-center justify-evenly hidden text-sm">
            <div className="group">
              <a href="#features" className="uppercase">
                Features
              </a>
              <div className="h-1 bg-secondaryTextColor w-0 group-hover:w-full transition-all transform duration-300 ease-in-out"></div>
            </div>
            <div className="group">
              <a href="#about" className="uppercase">
                About
              </a>
              <div className="h-1 bg-secondaryTextColor w-0 group-hover:w-full transition-all transform duration-300 ease-in-out"></div>
            </div>
            <div className="group">
              <a href="#contact" className="uppercase">
                Contact Us
              </a>
              <div className="h-1 bg-secondaryTextColor w-0 group-hover:w-full transition-all transform duration-300 ease-in-out"></div>
            </div>
            <Link to="/login">
              <button className="px-6 py-3 bg-secondaryTextColor rounded-3xl shadow-secondaryTextColor shadow-2xl hover:animate-wave">
                <a href="#contact">Download</a>
              </button>
            </Link>
          </div>
          <div className="md:hidden h-full flex items-center">
            {navbarOpen ? (
              <i
                class="fa-solid fa-bars text-textColor"
                onClick={() => setNavbarOpen(!navbarOpen)}
              ></i>
            ) : (
              <div className="absolute top-0 right-0 w-fit h-fit bg-background z-50 px-24 mt-10">
                <i
                  class="fa-solid fa-times text-textColor"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                ></i>
                <div className="flex flex-col items-center justify-center h-full">
                  <div className="group">
                    <a href="#features" className="uppercase underline">
                      Features
                    </a>
                    <div className="h-1 bg-secondaryTextColor w-0 group-hover:w-full transition-all transform duration-300 ease-in-out"></div>
                  </div>
                  <div className="group">
                    <a href="#about" className="uppercase underline">
                      About
                    </a>
                    <div className="h-1 bg-secondaryTextColor w-0 group-hover:w-full transition-all transform duration-300 ease-in-out"></div>
                  </div>
                  <div className="group">
                    <a href="#contact" className="uppercase underline">
                      Contact Us
                    </a>
                    <div className="h-1 bg-secondaryTextColor w-0 group-hover:w-full transition-all transform duration-300 ease-in-out"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </nav>
        <Hero />
        <Features />
        <About />
        <Contact />
      </div>
    </BrowserRouter>
  );
}

export default App;
